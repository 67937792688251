import { Link } from 'react-router-dom';
import logo from '../../Assets/images/logo3.png';
const Header = ({ screenChanger }) => {
  return (
    <div className='w-full sticky top-0 h-16 bg-white'>
      <div className='flex items-center justify-between p-2'>
        <button onClick={screenChanger}>
          <div className='flex items-center'>
            <img className='w-23 h-10 mr-2' src={logo} alt='logo' />
            {/* <h1 className='text-2xl font-bold'>Simplespect™</h1> */}
          </div>
        </button>
        <div className='flex gap-2 sm:gap-8 m items-center'>
          <h6 className='text-xs font-bold sm:text-lg'>
            <a
              href='https://lakesideconsultants.net/simplespect-privacy-policy/'
              target='_blank'
            >
              Privacy Policy
            </a>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Header;
