import React, { useRef, useState } from "react";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import Input from './Input'

const PlaceComponent = ({values, setValues, handleInputChange}) => {
    const inputRef = useRef();
    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) { 
            setValues({...values, ['address']: place.formatted_address,})
            // console.log(place.geometry.location.lat())
            // console.log(place.geometry.location.lng())
        } 
    }

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <StandaloneSearchBox
                    onLoad={ref => inputRef.current = ref}
                    onPlacesChanged={handlePlaceChanged}
                >
                    <Input
                        type= 'text'
                        label='Address*'
                        placeholder='Enter Your Address'
                        name='address'
                        className='pt-2'
                        changeHandler={handleInputChange}
                    />
                </StandaloneSearchBox>
        </LoadScript>
    );
};

export default PlaceComponent;